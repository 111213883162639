#add {
    width: 6rem;
    height: 6rem;
}

.modal {
    background-color: white;
    outline: none;
    border-radius: 3px;
    padding: 20px;
}

#uploadContentSpace {
    height: 180px;
}

#studentsUI {
    min-height: 600px;
    min-width: 350px;
}

#imageUploadControls {
    text-align: center;
    height: auto;
    
    padding: 10px;
}

.wbFormField {
    width: 180px;
}

.imageUploadProgress, .uploadSuccessMessage {
    text-align: center;
    padding: 10px;
}

.uploadSuccessMessage {
    color: green;
}

.imagePreview {
    width: 400px;
}

.noImage {
    width: 100%;
    height: 135px;
}
.noImageWrapper {
    width: 100%;
    text-align: center;
}

.noImageText {
    opacity: 0.6;
    justify-content: center;
    align-items: center;
}

.actionsSection {
    margin-left: 220px;
    margin-top: -5px;
    height: 15px;
}

.wordbankDescription {
    height: 50px;
}

#studentNameTable tr td, #studentStatsTable tr td, #studentStatsTable tr th {
    padding: 10px;
    text-align: left;

}

#studentNameTable, #studentStatsTable {
    margin-bottom: 20px;
    text-align: left;
}

#studentSelection {
    text-align: center;
    margin: 25px;
}

.modalHeading {
    text-align: center;
}

.microphone {
    color: white;
}
