#accountSettings {
    height: 24px;
    width: 24px;
    color: white;
}
#navbar {
    background-color: black;
    display: flex;
    z-index: 10000;
}

#navSubHeader {
    padding: 10px;
    border-bottom: 1px solid rgba(10, 10, 10, 0.1);
    font-size: 18px;
}

.navLink {
    text-decoration: none;
    font-size: 18px;
}

#navbarLogo {
    padding-top: 5px;
}
.navLinkContainer {
    padding-left: 20px;
}

.tile {
    box-shadow: 0px 0px 8px rgba(10,10,10,0.3);
    width: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    padding: 50px 30px 40px 30px;
}

.authFormField {
    margin: 0 auto;
    width: 150px;
}

.authButtonContainer {
    padding-top: 30px;
}

.tileContainer {
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 24px;
}

.VCHR {
    background: linear-gradient(45deg, red 0%, orange 25%, yellow 50%, green 75%, blue 100%);
    width: 80%;
    height: 1px;
    position: relative;
    left: 10%;
}

.loadingView {
    width: 100vw;
    height: 100vh;
    padding-top: 10vh;
}