#accessInformation {
    width: 30vw;
    display: flex;
    flex-direction: row;
}

.actionButtonWrapper {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
}

.actionButton{
    width: 170px;
}

.bio, .feature {
    text-align: center;
    color: white;
    width: 200px;
}

.bioImage {
    width: 200px;
    height: auto;
    box-shadow: 5px 5px 8px black;
    border-radius: 3px;
}

.bioHeading, .featureHeading {
    height: 30px;
}

.bioText, .featureText {
    height: 200px;
}

#footerSection {
    width: 100vw;
}

#homePageBG {
    background-color: #06101f;
    background-size: cover;;
}

#homePageContent {
    padding: 0px;
}

.detailSection {
    margin-bottom: 10px;
    padding: 25px;
}

.detailSubtext {
    color: white;
}

.detailComponent {
    width: 285px;
    margin-top: 20px;
}

.textDetailWrapper {
    max-width: 30vw;
}

.textDetailContainer {
    height: 100%;
}

@media only screen and (max-width: 899px)  {
    .detailComponent {
        width: 65vw;
    }

    .textDetailWrapper {
        max-width: 90vw;
    }

    .textDetailHeading, .detailSubtext {
        text-align: center;
    }

    .actionButtonWrapper {
        margin-right: 0px;
        flex-direction: column;
        margin-bottom: 15px;
    }
    
    .actionButton{
        width:65vw;
    }

    #accessInformation {
        width: 65vw;
        flex-direction: column;
    }
}

.homePageDetailComponentBG {
    overflow-x: hidden;
}

.videoReelBGImageWrapper {
    background-repeat: no-repeat;
    height: 500px;
    background-position: center;
}

.googleSignIn {
    text-transform: none;
}